
.navbar {
    margin-bottom: 50px;
}

.modal-xl {
    max-width: 800px;
}


.App-header {
    background-color: #222;
    height: 100px;
    padding: 20px;
    color: white;
}
