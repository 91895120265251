.word-wrap {
    word-break: break-all;
}
.badge{
    margin-right: .5em;
}
.v-center {
    display: flex;
    align-items: center;
}

.no-wrap {
    white-space: nowrap;
}

.fixed {
    table-layout: fixed;
}

.wrapText {
    word-break: break-all;
}

.big-fat-grid {
    max-width: 100% !important;
}

.uri-table {
    table-layout: fixed;
    width: 100%;
    word-wrap: break-word;
}

.uri-table-column {
    overflow: hidden;
    width: 100%;
    max-height: 6em;
}
