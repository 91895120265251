
.toolbar-container{
    justify-content: center
}

.workspace-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center
}


.workspace-container .card {
    margin: .5em .5em 1.5em;
    width: 220px;
    flex-grow: 0;

}

.card-title img {
    right: 1em;
    top: 1em;
    position: absolute;
    display: table-cell;
    vertical-align: middle
}


.workspace-container .card-body h5 {
    margin: auto;
    line-height: 2em;
    vertical-align: middle;
}

.workspace-container .img-thumbnail{
    background-color: #f1eded;
}
.workspace-container .card-title {
    margin-bottom: 0;
    margin-top: -.2em;
}


.workspace-container .card .btn {
    width: 100%;
}
