.Difference {
    font-family: monospace;
}

.align-right-in-row {
    margin-left: auto;
    margin-right: 15px;
}

.Difference > del {
    background-color: rgb(255, 224, 224);
    text-decoration: none;
}

.Difference > ins {
    background-color: rgb(201, 238, 211);
    text-decoration: none;
}

.custom-tooltip {
    margin: 0px;
    padding: 10px;
    background-color: rgb(255, 255, 255);
    border: 1px solid rgb(204, 204, 204);
    white-space: nowrap;
}

table {
    table-layout: fixed;
}

table th, table td, table tr {
    overflow: hidden;
}

.no-overflow {
    overflow: hidden;
    overflow-y: auto;
    max-height: 10em;
}


