.filterBox {

    padding: 2em;
    background: whiteSmoke;
    border: 1px solid #e5e5e5;
    margin-bottom: 2em;
}

.uri-filter {
    margin-top: 1rem;
    display: flex;
    align-items: center;
}
