
.country-code {
    position: relative;
}

.country-code img {
    position: absolute;
    background: #f9f9f9;
    top: .2em;
    height: 2em;
    right: 1.1em;
    z-index: 10;

    padding: 0.1rem;
    border: 1px solid #dee2e6;
    border-radius: 0.25rem;
    max-width: 100%;
}

.marginbottom {
    margin-bottom: 1em;
}
