
.tab-panel {
    padding-left: 3px;
    padding-right: 3px;
}



.dropzone-container{
    text-align: center;
    padding-top: 20px;
    padding-bottom: 20px;

}
.dropzone {
    display: flex;
    text-align: center;
    align-items: center;
    width : 100%;
    height : 100px;
    border : 1px dashed black;
}

.dropzone p {
    width:100%;
    text-align: center;
    font-weight: bold;
}
