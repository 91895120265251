
.field-editor-form label {
    font-weight: bold;
    color: #a198a1;
}

.large80 {
    min-width: 80%;
}

.recommended-button {;
    float: right;
    padding: 0.25rem 0.5rem;
    margin-left: .5rem;
    font-size: 0.8rem;
}

#recommendationLabel {
    margin-bottom: 1rem;
}

#recommendationLabel label {
    height: 2rem;
    margin: .5rem 0 -.2rem 0;
}

.center-vertically {
    display: flex;
    vertical-align: middle
}

.recommendedTextLabel {
    padding: 0.25em 0 0.25em 0;
}

.highlight {
    /*background-color: #f7f7f9;*/
    flex: 1 1 auto;
    padding: 1.25rem;
    border-radius: .25rem;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, .125);
    margin: 0rem 0 1rem 0;
}

.fieldEditorHeader {
    padding-left: .3rem;
}

.cardWithMargin {
    margin-bottom: 1.2rem;
}

.rounded-list {
    counter-reset: li;
}

.rounded-list-text{
    margin: .2rem 0 .3rem 0;
}

.rounded-list li {
    padding: .4em .4em .4em 3em;
}

.rounded-list li:before {
    content: counter(li);
    counter-increment: li;
    position: absolute;
    left: -0.2rem;
    top: 50%;
    margin-top: -1.3em;
    background: #87ceeb;
    height: 2.4rem;
    width: 2.4rem;
    line-height: 2em;
    border: .3em solid #fff;
    text-align: center;
    font-weight: bold;
    border-radius: 2em;
}
