
.added {
    background-color: #95c4f9;
    color: black;
    /*font-weight: bold;*/
}

.removed {
    background-color: rgba(255, 0, 20, 0.6);
    color: black;
    /*font-weight: bold;*/
    /*text-decoration: line-through;*/
}

.rowHeader {
    width: 9em;
}

pre {
    margin-bottom: 0;
}

.very-large {
    max-width: 95%;
}
